/*
	eslint
				eqeqeq:					"off"
			,	no-unused-vars:			"off"
			,	no-extend-native:		"off"
			,	no-whitespace-before-property	:	"off"
			,	no-unused-vars					:	"off"
			,	radix							:	"off"
			,	no-unexpected-multiline			:	"off"
			,	react/jsx-equals-spacing		:	"off"
			,	react/jsx-pascal-case			:	"off"
			,	no-cond-assign:			"off"
			,	guard-for-in:			"off"
			,	no-redeclare:			"off"
			,	array-callback-return:	"off"
			,	no-extend-native:		"off"
			,	new-parens:				"off"
*/
// import 'babel-polyfill'

if	(
		window.mem
	===	undefined
	)	{
		window.mem	=	{}
		}

class	permanentStorage_class
{
	constructor()
		{
		// this.mem	=	window.mem
		}


	exists	(x)
		{
		let	y
		if	(
				window.mem[	x ]
			===	undefined
			)	{
				y	=	localStorage.getItem(
											x
											)
				if(y === null)
					return 0;

				window.mem[	x ]	=	y
				}

		return	1
		}


	getItem	(x)
		{
		let	y
		if	(
				window.mem[	x	]
			===	undefined
			)	{
				y	=	localStorage.getItem(
											x
											)
				window.mem[	x	]	=	y
				}


		return	window.mem[	x	]
		}


	setItem	(
				x
			,	y
			)
		{
		window.mem[	x	]	=	y.toString()
		try
			{
			localStorage.setItem(
									x
								,	y.toString()
								// ,	y
								)
			}
		catch(e)
			{}
		}


	clear	(
			x	=	false
			)
		{
		if	(x)
			{
			localStorage.removeItem( x )
			}
		else
			{
			localStorage.clear()
			window.mem	=	{}
			}
		}



}

var	permanentStorage	=	new	permanentStorage_class()

// alert('a')

// permanentStorage.setItem	(
// 					'x'
// 				,	'y'
// 				)

// alert('b')

// export	{
// 		permanentStorage
// 		}

export	default permanentStorage 