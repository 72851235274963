import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import * as Sentry from '@sentry/browser';
// Sentry.init(
// 	{
// 		dsn: "https://d5816810368b493fb470040565ae0033@o388221.ingest.sentry.io/5224760"
// 	,	sampleRate: 0.02
// 	}
// 	);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
