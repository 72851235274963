import React from 'react';
import logo from './logo.svg';
import Trytool	from './Trytool';
import Download	from './Download';
import Top		from './Top';
import Footer	from './Footer';
import './App.css';
import permanent_storage 	from "./permanent_storage";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import	{
			OurApp
		}					from	'./videos'
import	{
			is_targeted_app_platform
		,	is_iOS
		,	is_Android
		}					from	'./platform.js'

var consent = 0	//	0 - didn't ask,  1 - asked, okay,  2 - asked, don't store photos
if(permanent_storage.exists('consent'))
	consent = permanent_storage.getItem('consent')


window.api =	{
				may_keep	:	0
				}
if	(consent==1)
	window.api.may_keep = 1

// else
// 	consent = permanent_storage.setItem('consent',0)



// import	{
// 			Ad
// 		,	init_ads
// 		}	from './ad.jsx'




function App() 
	{
	const [openModal, setOpenModal] = React.useState(!consent)
	// const [openModal, setOpenModal] = React.useState(true)

		return	(
				<div className="App">



					{//
					0 && !is_iOS && !is_Android
					?	(
						<div
							className="consent_modal"
							>
							<Modal
								open={openModal} 
								onClose={
										()=>{
											consent = 1
											permanent_storage.setItem('consent',1)
											setOpenModal(0)
											}
										}
								center
								classNames={{
											overlay: 'customOverlay',
											modal: 'customModal',
											}}
								>
								<h2>Privacy</h2>
								<p
									className="consent-p"
									>
									Just like lots of other websites, we place data on your device and share tracking data with third parties. This helps us to, for example, analyze how the website is used and to optimize ads.
									{/*
									Main points:
									<ul>
										<li>If you're okay with this, we'd like to store the part of photos that we process into styles long-term to grow our machine learning dataset.</li>
										<li>If you're a child under 16, we're not allowed to store your data long-term.</li>
										<li>Just like lots of other websites, we place data on your device and share tracking data with third parties including Google. This helps us to, for example, analyze how the website is used and to show better ads.</li>
									</ul>
									*/
									}
								</p>
									<button
										className="consent-btn"
										onClick={
												()	=>	{
														consent = 1
														permanent_storage.setItem('consent',1)
														window.api.may_keep = 1
														setOpenModal(0)
														}
												}
										>
										Okay. And I release my photos into public domain
									</button>
									<button
										className="consent-btn"
										onClick={//
												()	=>	{
														consent = 2
														permanent_storage.setItem('consent',2)
														window.api.may_keep = 0
														setOpenModal(0)
														}
												}
										>
										Okay. But don't store any part of my photos long-term
									</button>

							</Modal>
						</div>
						)
					//
					:	''
					}


					<Top  />
					<Trytool  />

					{
					is_targeted_app_platform
					?	(
						<div>
							<br />
						</div>
						)
					:	''
					}
				</div>
				);
			//
	}

export default App;
