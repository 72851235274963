/*
	eslint
				eqeqeq:					"off"
			,	no-unused-vars:			"off"
			,	no-extend-native:		"off"
			,	no-whitespace-before-property	:	"off"
			,	no-unused-vars					:	"off"
			,	radix							:	"off"
			,	no-unexpected-multiline			:	"off"
			,	react/jsx-equals-spacing		:	"off"
			,	react/jsx-pascal-case			:	"off"
			,	no-cond-assign:			"off"
			,	guard-for-in:			"off"
			,	no-redeclare:			"off"
			,	array-callback-return:	"off"
			,	no-extend-native:		"off"
			,	new-parens:				"off"
			,	no-useless-concat:		"off"
			,	no-restricted-globals:		"off"
*/

let	p	=	navigator.platform.toLowerCase()
let	u	=	navigator.userAgent.toLowerCase()
let	v	=	navigator.vendor.toLowerCase()

function getAndroidVersion(ua) {
	ua = (ua || navigator.userAgent).toLowerCase(); 
	var match = ua.match(/android\s([0-9\.]*)/);
	return match ? match[1] : undefined;
};


var
	os	=
				['ipad', 'iphone', 'ipod'].indexOf( p )
			>	-1
			?
				'iOS'
			:
				(
						u.indexOf( 'android' )
					>=	0
				||
						u.indexOf( 'iemobile' )			//	not exacyly Android, but probably convenient to say it's similar
					>=	0
				||
						u.indexOf( 'windows phone' )	//	not exacyly Android, but probably convenient to say it's similar
					>=	0
				||
						u.indexOf( 'webos' )			//	not exacyly Android, but probably convenient to say it's similar
					>=	0
				||
						u.indexOf( 'blackberry' )		//	not exacyly Android, but probably convenient to say it's similar
					>=	0
				?
					'Android'
				:
					'other'	//	desktop
				)
	
// os = 'iOS'	//!!!
// os = 'Android'	//!!!

var		os_detail	=		os
								==	'Android'
							?
								parseInt( getAndroidVersion() )
							:
								''
	,
		browser_detail	=	''
	,
		is_ie11	=
						(
							u.indexOf('11')
						>	-1
						)
					&&
						(
							u.indexOf('trident/7.')
						>	-1
						)

// os		=	'iOS'
// os		=	'Android'
// os		=	'other'

const
	considered_mobile	=
								['iOS', 'Android'].indexOf( os )
							>=	0
	// considered_mobile	=1





try
	{
	if	(
			/Safari/.test(navigator.userAgent)
		&&
			/Apple/.test(navigator.vendor)
		)
			browser_detail	=	'safari'
	}
catch(e){}


if	(
	is_ie11
	)
		browser_detail	=	'ie11'

if	(
		p
	===	"macintel"
	)
		os_detail	=	'mac'






const
	orientation	=		window.screen.orientation
					||	window.screen.mozOrientation
					||	window.screen.msOrientation


const
	is_iOS	=	
				['iOS'].indexOf( os )
				>=	0 

const
	is_apple	=	
				os == 'iOS' || browser_detail=='safari' || os_detail=='mac' 

const
	is_Android	=	
				os == 'Android'

const
	is_targeted_app_platform	=	
									is_iOS || is_Android

const
	lang	=	(navigator.language || navigator.userLanguage).toLowerCase()

const
	visitor_from_chosen_country	=
		[
			'pt-br',	'es-419',	'zh-tw',	'fo',		'id'
		 ,	'id-id',	'es-pe',	'es-pa',	'es-ni',	'es-hn'
		 ,	'es-gt',	'es-ec',	'es-do',	'es-cr',	'es-co'
		 ,	'es-cl',	'es-bo',	'es-ar',	'es-pr',	'es-py'
		 ,	'es-sv',	'es-uy',	'es-ve',	'fa',		'fa-ir'
		 ,	'fo-fo',	'gu',		'gu-in',	'hi',		'hy'
		 ,	'hy-am',	'ka',		'ka-ge',	'kk',		'kn'
		 ,	'kn-in',	'kok',		'kok-in',	'ky',		'ky-kg'
		 ,	'mi',		'mk',		'mk-mk',	'mn',		'mn-mn'
		 ,	'mr',		'mr-in',	'ms',		'ms-my',	'mt'
		 ,	'ns',		'ms-za',	'pa',		'pa-in',	'ps'
		 ,	'ps-ar',	'qu',		'qu-bo',	'qu-ec',	'qu-pe'
		 ,	'he-il',	'sa',		'sa-in',	'sw',		'sw-ke'
		 ,	'syr',		'syr-sy',	'ta',		'ta-in',	'te'
		 ,	'te-in',	'th',		'th-th',	'tl',		'tl-ph'
		 ,	'tn',		'tn-za',	'ur',		'ur-pk',	'vi'
		 ,	'vi-vn',	'xh',		'xh-za',	'zh',		'zh-cn'
		 ,	'zu',		'zu-za',	'af',		'af-za',	'ar-dz'
		 ,	'ar-iq',	'ar-jo',	'ar-lb',	'ar-ly',	'ar-ma'
		 ,	'ar-om',	'ar-sy',	'ar-ye',	'en-ph'

		 ,	'sr-rs',	'zh-hk',	'lv-lv',	'he',		'fr-dz'
		 ,	'zh-tw',	'da',		'ro',		'ro-ro',	'de-at'
		 ,	'cs',		'cs-cz',	'fr-ma',	'el-gr',	'en-ie'
		 ,	'hu-hu',	'sv',		'fr-ca',	'hr-hr',	'ja-jp'
		 ,	'ja',		'es-mx',	'en-in'

		].includes(lang)

const
	country_tier_2	=
		[
		 	'ar-eg',	'ar',		'tr',		'tr-tr'
		 ,	'ko', 		'ko-kr',	'ar-ae',	'fr'
		 ,	'fr-fr',	'es',		'es-es',	'de-de'
		 ,	'it',		'it-it',	'nb-no',	'de-ch'
		 ,	'pl-pl',	'pl',		'es-us',	'de'
		 ,	'nl',		'nl-nl',	'hr-hr',	'fr-ca'
		 ,	'en-au',	'en-ca'
		].includes(lang)

const
	country_tier_our	=
		[
		 	'uk-ua'
		 ,	'uk'
		 ,	'ru-ru'
		 ,	'ru'
		 ,	'en-us'
		 ,	'en-gb'
		 ,	'en'
		].includes(lang)

// alert(lang)
const
	is_china	=	['zh-cn'].includes(lang)

const
	selected_visitor	=	(
								country_tier_our
							// 	(
							// 		(
							// 			(os == 'Android')  
							// 		)
							// 	// &&  
							// 	// 	(
							// 	// 		is_china
							// 	// 	||
							// 	// 		(
							// 	// 			visitor_from_chosen_country
							// 	// 		&&	Math.random() > 0.3
							// 	// 		)
							// 	// 	||
							// 	// 		(
							// 	// 			country_tier_2
							// 	// 		&&	Math.random() > 0.5
							// 	// 		)
							// 	// 	)
							// 	)
							// ||
							// 	(
							// 		(
							// 			(os == 'iOS')  
							// 		)
							// 	&&  
							// 		(
							// 			is_china
							// 		||
							// 			(
							// 				visitor_from_chosen_country
							// 			&&	Math.random() > 0.6
							// 			)
							// 		||
							// 			(
							// 				country_tier_2
							// 			&&	Math.random() > 0.8
							// 			)
							// 		)
							// 	)
							)

export	{
			os
		,	os_detail
		,	orientation
		,	browser_detail
		,	considered_mobile
		,	is_targeted_app_platform
		,	is_apple
		,	is_iOS
		,	is_Android
		,	lang
		,	visitor_from_chosen_country
		,	selected_visitor
		}
