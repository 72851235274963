import React from 'react';
import	{
			os
		,	os_detail
		,	browser_detail
		,	considered_mobile
		,	is_apple
		,	is_iOS
		,	is_Android
		}					from	'./platform.js'
import	{
		itunes_link
		}					from	'./links.js'
import	{
			Norah
		,	Snails
		,	Transitions
		,	Transitions_1
		,	OurApp
		}					from	'./videos'

// https://itunes.apple.com/app/apple-store/id1474684190
						// <img id="logo-button"	src="./images/logo-large.png" />
						// <br/>
function Top() 
	{
	// if	(is_apple)
	// 	return	(
	// 			<div id="top">

	// 				<div id="logo-stuff">
	// 					<a href="https://itunes.apple.com/app/apple-store/id1474684190">
	// 					Portrait AI
	// 					</a>
	// 				</div>


	// 				<img className="person" alt="Portrait of Emmanuel Macron by Portrait AI"	src="./images/macron-rev.jpg" />
	// 				<img className="person" alt="Portrait of Justin Trudeau by Portrait AI"	src="./images/trudeau-rev.jpg" />
	// 				<img className="person" alt="Portrait of Angela Merkel by Portrait AI"	src="./images/merkel-rev.jpg" />
	// 				<img className="person" alt="Portrait of Queen Elizabeth II by Portrait AI"	src="./images/elizabeth-rev.jpg" />



	// 			</div>
	// 			);
	// else
	// 				// <img id="logo-button"	src="./images/logo-large.png" />
	// 				// <br/>
		return	(
				<div id="top">


					{
					is_iOS || is_Android
					?	
						(
						<div>
							<img className="person top-small-margin" alt="Portrait of Justin Trudeau by Portrait AI"	src="./images/trudeau-rev.jpg" />
							<img className="person top-small-margin" alt="Portrait of Angela Merkel by Portrait AI"	src="./images/merkel-rev.jpg" />
						</div>
						)
					:	''
						// (
						// <div>
						// 	<div className="top-small-margin">
						// 		<Transitions />
						// 	</div>
						// </div>
						// )
					}



				</div>
				);
	}

export default Top;