import React from 'react';

function Footer() 
	{
	return (<></>)
	// return	(
	// 		<div id="footer">
	// 			<a href="https://portraitai.com/privacy.html">
	// 				privacy
	// 			</a>
	// 			<a href="https://portraitai.com/terms.html">
	// 				terms
	// 			</a>
	// 			<a href="mailto:hello@facefun.ai">
	// 				email
	// 			</a>
	// 		</div>
	// 		);
	}

export default Footer;
