//	CRC32
var makeENCTable = function(){
	var c
	var ENCTable = []
	for(var n =0; n < 256; n++)
		{
			c = n
			for(var k =0; k < 8; k++)
				{
				c = ((c&1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1))
				}
			ENCTable[n] = c;
		}
	return ENCTable;
}

window.ENCTable = makeENCTable()


// function stringToBytesFaster ( str ) { 
// var ch, st, re = [], j=0;
// for (var i = 0; i < str.length; i++ ) { 
//     ch = str.charCodeAt(i);
//     if(ch < 127)
//     {
//         re[j++] = ch & 0xFF;
//     }
//     else
//     {
//         st = [];    // clear stack
//         do {
//             st.push( ch & 0xFF );  // push byte to stack
//             ch = ch >> 8;          // shift value down by 1 byte
//         }
//         while ( ch );
//         // add stack contents to result
//         // done because chars have "wrong" endianness
//         st = st.reverse();
//         for(var k=0;k<st.length; ++k)
//             re[j++] = st[k];
//     }
// }   
// // return an array of bytes
// return re; 
// }

// function stringToBytes ( str ) {
//   var ch, st, re = [];
//   for (var i = 0; i < str.length; i++ ) {
//     ch = str.charCodeAt(i);  // get char 
//     st = [];                 // set up "stack"
//     do {
//       st.push( ch & 0xFF );  // push byte to stack
//       ch = ch >> 8;          // shift value down by 1 byte
//     }  
//     while ( ch );
//     // add stack contents to result
//     // done because chars have "wrong" endianness
//     re = re.concat( st.reverse() );
//   }
//   // return an array of bytes
//   return re;
// }


var ENC32 = function(str) 
	{

	// console.log("str", typeof str, str)

	// // str = Array.from (str)
	// str = new Uint8Array(str)

	// str=	stringToBytes ( str )

	// console.log("str", typeof str, str)
	// str = UInt8Array(str)
	// console.log("str", typeof str, str)
	// str=	stringToBytesFaster ( str )
	var ENCTable = window.ENCTable || (window.ENCTable = makeENCTable())
	var ENC = 0 ^ (-1)

	for (var i = 0; i < str.length; i++ ) 
		{
		// ENC = (ENC >>> 8) ^ ENCTable[(ENC ^ str[i]) & 0xFF]
		ENC = (ENC >>> 8) ^ ENCTable[(ENC ^ str.charCodeAt(i)) & 0xFF]
		}

	return (ENC ^ (-1)) >>> 0
	}

// console.log( ENC32("Hello!") )
// console.log( ENC32("Hello!") )






export default ENC32