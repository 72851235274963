import	React
	,	{	
			useState
		,	useEffect
		,	Component
		}	from 'react'
import Dropzone	from './Dropzone'
import	{
			is_iOS
		,	is_Android
		,	visitor_from_chosen_country
		,	selected_visitor
		}					from	'./platform.js'
import	{
			itunes_link
		,	g_play_link
		}	from	'./links.js'
import	{
			Norah
		,	Snails
		,	Transitions
		,	Transitions_1
		,	Transitions_Android
		,	OurApp
		}					from	'./videos'
import	{
		Download
		}	from './Download';
import	{Email_Signup}				 	from "./email";

var gtag = window.gtag




let logo_rand = Math.random() >0.5



function Trytool() {

	/*
	const [logos, setLogos] = useState(0);


	let em	=	(
			<img
				className="app-logos"
				src="./app-logos/m.png"
				onClick=	{
							(e)=>
								{
								gtag('event',	'em'
										,	{
											event_label		:'em',
											event_category	:'app-logos'
											})
								setLogos(1)
								}
							}
				 />
			)
	let ghost=	(
			<img
				className="app-logos"
				src="./app-logos/ghost.png"
				onClick=	{
							(e)=>
								{
								gtag('event',	'ghost'
										,	{
											event_label		:'ghost',
											event_category	:'app-logos'
											})
								setLogos(1)
								}
							}
				 />
			)
	*/

	return (
		<div id="trytool">
			{/*
				!is_Android  &&  !is_iOS
			?	(
				<div>
					<div id="trytool-letspaint">
						Help us choose the best logo
					</div>
					<div>
						{
							logos 
						?
							'Thanks!'
						:
							(
							<div>
								Click on the best app logo.

								<br/>
								<br/>
								<div>
									{
										logo_rand
									?	
										(
										<div>
											{em}
											{ghost}
										</div>
										)
									:	
										(
										<div>
											{ghost}
											{em}
										</div>
										)
									}
								</div>
							</div>
							)
						}
					</div>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
				</div>
				)
			:	''
			*/
			}
			{
			// <div id="trytool-letspaint">
				// Let's paint Your portrait!
			// </div>
			}




			{
			// <p className="oops">
			// <strong>PortraitAI is in Early Access</strong> development stage.
			//  Have low expectations.
			//  Things don't work too great yet and our server doesn't 
			//  even work like an hour per day. Big improvements and faster portrait generation coming in the future. Be sure to check back later.


			// <br />
			// <br />
			// <b>BIG NOTE!</b> Right now the AI is overloaded and often doesn't work. We're patching, but this isn't going to be properly fixed today yet. I recommend you check back here in a few days instead.
			// <br />
			// <br />
			// It totally sucks right now, come back later instead, seriously.
			// <br />
			// <br />
			//  I beg you. It's like standing in very long traffic jam.
			// <br />
			// <br />
			// Last chance... Please. Don't do it. Yet. It's going to be best for everyone if you come back later when it actually works.
			// <br />
			// <br />
			// <br />
			// <br />
			// You're on your own now...
			// <br />
			// {
			// <br />
			// <button onСlick="askNotificationPermission()">Notify me!</button>
			// <button onСlick="notifyMe()">Notify me!</button>
			// <br />
			// <br />
			// ...When it does work, it's kind of fun already. 😜



			// <button onСlick="askNotificationPermission()">Notify Ask!</button>
			// <button onСlick="notifyMe()">Notify me!</button>
			// </p>
			}


		<Email_Signup />


		{
			is_iOS
		?	
			(
			<div className="full-ios-app-ad_under-letspaint">
					
				<Transitions_1 />

				<p className="ios-note-that-extreme">
					Generate portraits <a
					 	href={itunes_link}
						onClick=	{
									(e)=>
										{
										gtag('event',	'itunes_link'
												,	{
													event_label		:'Generate portraits in our free app.',
													event_category	:'link click'
													})
										}
									}
					 	>inside a free app.</a> We also have live video AI filters there.
				</p>


				<Norah />

				<div className="app_store_ios">
					<Download />
				</div>
			</div>
			)
		:	''
		}



		{
			is_Android
		?	
			(
			<div className="full-ios-app-ad_under-letspaint">
					<Transitions_Android />

				<p className="ios-note-that-extreme">
					Generate portraits <a
					 	href={g_play_link}
						onClick=	{
									(e)=>
										{
										gtag('event',	'g_play_link'
												,	{
													event_label		:'Generate portraits in our free app.',
													event_category	:'link click'
													})
										}
									}
					 	>inside a free app.</a>
				</p>


				<div className="store_btn">
					<Download />
				</div>
			</div>
			)
		:	''
		}


			<div className="Card">
				<Dropzone onFilesAdded={console.log} />
			</div>

		{
			// (selected_visitor  || !is_Android)
		// ?	(
		// 	<div className="Card">
		// 		<Dropzone onFilesAdded={console.log} />
		// 	</div>
		// 	)
		// :	''


		// :	
		// 	(
		// 		<div className="issue">
		// 			Our server is experiencing issues, please, come back later.
		// 		</div>
		// 	)
		}


		</div>
	);
}

export default Trytool;
