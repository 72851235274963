import	React
	,	{	
			useState
		,	useEffect
		,	Component
		}	from 'react'
import	{
			os
		,	os_detail
		,	browser_detail
		,	considered_mobile
		,	is_targeted_app_platform
		,	is_iOS
		,	selected_visitor
		}	from	'./platform.js'
import	{
			itunes_link
		,	g_play_link
		}	from	'./links.js'
import	enc	from	'./crc.js'

import {ReactComponent as Twitter_logo} from './svg/t.svg';
import {ReactComponent as FB_logo} 		from './svg/f.svg';
import {ReactComponent as Pin_logo} 	from './svg/pin.svg';
import {ReactComponent as Save_svg} 	from './svg/save_alt-white-30dp.svg';
import {ReactComponent as PortraitWhite}from "./svg/portrait-white-48dp.svg";
import {ReactComponent as Portrait} 	from "./svg/portrait.svg";
import {Email_Signup}				 	from "./email";
import	{
		Download
		}	from './Download';
import	{
		default_subdomain_list
		}	from './domains.js';

import permanent_storage 	from "./permanent_storage";

import * as Sentry from '@sentry/browser';
import to_data_url from './to_data_url';
import 'whatwg-fetch'; 
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import	{
			Norah
		,	Snails
		,	Transitions_1
		,	OurApp
		}					from	'./videos'
import	{
			Ad
		}					from	'./ad.jsx'
import	{
			Android_App_Presentation
		}					from	'./app-presentations.jsx'
var gtag = window.gtag
var	num_default_imgs_to_generate	=	0;
var	collages						=	[]
var	is_it_good						=	[]
var pic_id_previous					=	''
var should_nocrop_previous			=	0
var one_more_pic_pushed_times		=	0
var subdomain						=	-1
var send_to_retries					=	0
// let	default_subdomain_list			=	[3]
var pick_subdomain_retries			=	0
var pick_subdomain_retries_overall	=	0
var upload_error_retries   			=	0
var files_uploaded					=	{}
var	uploading_image_at_the_moment	=	0;


function random_choice(arr) 
	{
	return arr[Math.floor(Math.random() * arr.length)]
	}


function pick_subdomain( on_success=()=>{} )
	{
	/* Only for working version

	if (pick_subdomain_retries > 50)
		return;
	if (pick_subdomain_retries > 150)
		return;

	//	get a list of subdomains
	let	rnd 		=	random_choice(default_subdomain_list)
	let	path		=	'https://a'+ rnd +'.portrait-ai.com/v1/c/rand-subdomain.php'
	// let	path		=	'https://portraitai.com/v1/c/rand-subdomain.php'
	let	fetch_json	=	{
							method	:	'GET'
						,	mode	:	'cors'
						}

	function suc(x)
		{
		if	(x.length > 5)
			{
			//	"Database Issue." response or something similar.
			setTimeout	(
							()=> pick_subdomain(on_success)
						,	100
						)
			}

		x = parseInt(x)

		if	(x == -1)
			{
			pick_subdomain_retries ++
			pick_subdomain_retries_overall ++
			setTimeout	(
							()=> pick_subdomain(on_success)
						,	2020
						)
			return x
			}

		subdomain	=	x
		pick_subdomain_retries = 0
		on_success()
		}

	function err(e)
		{
		console.error(e)
		pick_subdomain_retries ++
		pick_subdomain_retries_overall ++
		setTimeout	(
						()=> pick_subdomain(on_success)
					,	100
					)
		}

	fetch	(
				path
			,	fetch_json
			)
		.then	(response	=>	response.text())
		.then	(success 	=> 	suc(success))
		.catch	(error 		=>	err(error))

	*/
	}
pick_subdomain()
// subdomain=3




/*
not_processing_at_the_moment == 0 when processing
not_processing_at_the_moment == 2 when last request was loaded
not_processing_at_the_moment == 3 when painting
*/


// let	do_we_add_watermark = 0
// if(Math.random()>0.5)
// 	do_we_add_watermark = 1


// gtag('event',	do_we_add_watermark
// 		,	{
// 			event_label		:do_we_add_watermark,
// 			event_category	:'do_we_add_watermark'
// 			});


// var	num_default_imgs_to_generate = 15;
// var	num_default_imgs_to_generate = 7;
// var	num_default_imgs_to_generate = 50;

function makeid(length) 
	{
	var result           = '';
	var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for ( var i = 0; i < length; i++ ) 
		{
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
	return result;
	}

let uid = makeid(4)


if(permanent_storage.exists('uid'))
// if(permanent_storage.getItem('uid'))
	uid = permanent_storage.getItem('uid')
else
	permanent_storage.setItem('uid',uid)

if(uid === undefined )
	{
	uid = makeid(4)
	permanent_storage.setItem('uid',uid)
	}

console.log('u',uid)


function	Loader()
	{
	return	(
			<div className="loader">
				<div className="loader-inner">
					<div className="loader-line-wrap">
						<div className="loader-line"></div>
					</div>
					<div className="loader-line-wrap">
						<div className="loader-line"></div>
					</div>
					<div className="loader-line-wrap">
						<div className="loader-line"></div>
					</div>
					<div className="loader-line-wrap">
						<div className="loader-line"></div>
					</div>
					<div className="loader-line-wrap">
						<div className="loader-line"></div>
					</div>
				</div>
			</div>
			)
	}



function	report_img_load()
	{
	// try	{
	// 	var gtag = window.gtag
	// 	gtag('event',	'checkout_progress');
	// 	}
	// catch(e){}
	}


function	shared(platform, photo_id)	//	photo_id = full path url to image
	{
	if	(
			photo_id.substring(photo_id.length-12)
		==	"original.jpg"
		)
		{
		return;
		}

	try	{
		const	formData	=	new FormData()
		let		from_		=	photo_id.indexOf('/cropped/')

		formData.append	(
							'hex'
						,   photo_id.substring	(
													from_ + 9
												,	from_ + 9 + 40
												)
						)

		let	stl = Number(photo_id.substring( photo_id.length - 15 ).replace(/\D/g,''))
		console.log(photo_id)
		console.log(stl)
		formData.append('style',  stl)

		fetch	(
					photo_id.substring(0,from_) + '/c/favourite.php'
				,	{
						method	: 'POST'
					,	body	: formData // This is your file object
					}
				)

		console.log("shared", photo_id, platform)
		

		// var gtag = window.gtag
		// gtag('event',	platform
		// 		,	{
		// 			event_label		:photo_id,
		// 			event_category	:'share_button_clicked'
		// 			});
		}
	catch(er){}
	}



function img_uploaded()
	{
	var el
	el = document.querySelectorAll(".my_photo")
	el = el[el.length -1]
	try
		{
		el.scrollIntoView()
		console.log('scrollIntoView to .my_photo')
		}
	catch(e){}

	try	{
		gtag('event',	'login'
				,	{
					method:		'Uploaded an image'
					});
		console.log("uploaded")
		}
	catch(er){}
	}



String.prototype.hashCode = function() {
    var hash = 0;
    if (this.length == 0) {
        return hash;
    }
    for (var i = 0; i < this.length; i++) {
        var char = this.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}



var	drop_zone_component = 0


var hashCodes_of_loaded = []
var img_loaded_increment = 0
function img_loaded()
	{
	img_loaded_increment++;
	if(img_loaded_increment%2==1)
		return;

	console.log('img_loaded')
	var el,all
	all = document.querySelectorAll(".processed")
	el = all[all.length -1]
	if(! el.hasAttribute('original') )
		{
		el = all[all.length -2]
		}
	try	{
		all.forEach(img =>
			{
			// console.log('img', img)
			let h_code =	Number.parseInt( img.getAttribute('hashcode') )
			// console.log('h_code', h_code)
			if(! (hashCodes_of_loaded.includes(h_code)) )
				{
				// console.log(h_code, "hashcode")
				hashCodes_of_loaded.push( h_code )
				}
			})
		}
	catch(e){}

	// if(not_processing_at_the_moment == 2)
	// 	{
		not_processing_at_the_moment = 1
		// }
	drop_zone_component.setState({ last_action:'loaded processed img' })

	el = document.querySelectorAll(".processed-div")
	el = el[el.length -1]

	setTimeout( ()=>{
					window.requestAnimationFrame(
						()=>{
							el.scrollIntoView()
							console.log('scrollIntoView to last .processed-div')
							}
						)
					}
				, 100
				)
	}






var last_alert = ''
var	not_processing_at_the_moment = 1
var retries = 0
var not_cropped = []

var base64_for = {}
let	disable_download_btn = 0
if(considered_mobile  &&  !(os == 'Android'))
	disable_download_btn = 1

function	HowToImg(props)
	{
	return	(
			<img
				className="how-to-photo"
				src='./images/new-how-to-take-photo.png'
				/>
			)
	}


function	Uploaded_Photos(props)
	{
	return	(
			<div
				className = {
							"container-uploaded-images"
								+	(
									props.sources.length
									?	''
									:	' invisible'
									)
							}
				>
				<div className=	{
									(
										considered_mobile
										?	'mobile'
										:	'desktop'
									)
								+	' all-Photos'
								}
					>
					<div className="uploaded-photos">
						{
						props.sources.map(
							(path, i)	=>
								(
								<div
									key				=	{ 'photo' + i }
									>
									<img
										className	=	"my_photo"
										src			=	{ path }
										onLoad		=	{ img_uploaded }
										/>
								</div>
								)
							)
						}
					</div>
				</div>
			</div>	
			)
	}


function	Photos(props)
	{
	const [openModal, setOpenModal] = React.useState(false)

	return	(
			<div
				className = {
							"container-showing-images"
								+	(
									props.sources.length
									?	''
									:	' invisible'
									)
							}
				>
				<div className=	{
									(
										considered_mobile
										?	'mobile'
										:	'desktop'
									)
								+	' all-Photos'
								}
					>
					{
					// <div className="uploaded-photos">
					// 	{
					// 	props.sources.map(
					// 		(path, i)	=>
					// 			(
					// 			<div
					// 				key				={ 'photo' + i }
					// 				>
					// 				<img
					// 					className	="my_photo"
					// 					src			={ path }
					// 					onLoad		={ img_uploaded }
					// 					/>
					// 			</div>
					// 			)
					// 		)
					// 	}
					// </div>
					}
					<div className="portrait_results">
						{
						props.results.map(
							(path, index)	=>
								{
								let ib	 = (index%6==0) ? 'b' : 'i'
								let	photo_id = path.substring( path.lastIndexOf('/') +1, path.lastIndexOf('-_port.') )
								let addr = 'https://portraitai.com/i?'+ib+'=' +	photo_id
								// console.log(photo_id,"photo_id")
								let hc	 = path.hashCode()
								let	path_with_original	= path.replace('-_port', '+_port')
								let self = props.this
								if ( !disable_download_btn  &&  !(hc in base64_for) )
									{
									base64_for[hc] = ''
									to_data_url(path_with_original
												,
													function(dataUrl) 
													{
													base64_for[hc] = dataUrl
													// console.log('dataUrl:', dataUrl)
													self.setState({last_action:'base64_update'})
													})
									}

								// console.log("showing. hashCodes_of_loaded", hashCodes_of_loaded)

								return (
									<div
										key				={ hc }
										className		="processed-div"
										style			=	{
																hashCodes_of_loaded.includes( hc )
															?	{}
															:	{display:'none'}
															}
										hashcode		={ hc }

										>
											<img
												className	=	"processed"
												src			=	{ path }
												onLoad		=	{ img_loaded }
												hashcode	=	{ hc }
												original	=	"true"
												/>
											<br/>
											{
											// <img
											// 	className	=	"processed"
											// 	src			=	{ path_with_original }
											// 	onLoad		=	{ img_loaded }
											// 	hashcode	=	{ hc }
											// 	/>
											// <br/>
											}
											<div className="above_shares">
												{
													not_cropped.includes( photo_id )
													?
														(
														<div
															className="near_shares_warning"
															>
															<div
																onClick	 =	{
																			()=>{
																				setOpenModal(1)
																				}
																			}
																>
																⚠️
															</div>
															<Modal
																open={openModal} 
																onClose={
																		()=>{
																			setOpenModal(0)
																			}
																		}
																center
																classNames={{
																			overlay: 'customOverlay',
																			modal: 'customModal',
																			}}
																>
																<h2>⚠️ Disoriented</h2>
																<p>
																AI wasn't able to properly frame a face, 
																so the result is likely to look pretty bad.
																Tips for best photos:
																</p>
																<HowToImg />
															</Modal>
														</div>
														)
													:	''
													//
												}
												<div
													className="shares">
													{
														/*
													<a
														target="popup"
														href= {'https://www.facebook.com/dialog/share?app_id=249182089820918&display=popup&href='+addr+'&redirect_uri='+addr}
														onClick={
																()=>{
																	shared("fb", photo_id)
																	let s = 'https://www.facebook.com/dialog/share?app_id=249182089820918&display=popup&href='+addr+'&redirect_uri='+addr
																	window.open(s,'popup','width=750,height=750');
																	return false;
																	}
																}
						 								>
						 								<FB_logo />
													</a>
													<a
														href=	{
																'https://twitter.com/intent/tweet?text=&hashtags=PortraitAI&url=' + addr
																}
														target="popup"
														onClick={
																()=>{
																	shared("twitter", photo_id)
																	let s = 'https://twitter.com/intent/tweet?text=&hashtags=PortraitAI&url=' + addr
																	window.open(s,'popup','width=750,height=750');
																	return false;
																	}
																}
														>
						 								<Twitter_logo />
													</a>
													<a
														target="popup"
														href=	{'http://pinterest.com/pin/create/button/?url=https://portraitai.com/&media=' + path
																}
														onClick={
																()=>{
																	shared("pinterest", photo_id)
																	let s = 'http://pinterest.com/pin/create/button/?url=https://portraitai.com/&media=' + path
																	window.open(s,'popup','width=750,height=750');
																	return false;
																	}
																}
						 								>
						 								<Pin_logo />
													</a>
													*/
													}
													{//
													disable_download_btn
													?
														''
													:	
														(
														is_it_good.includes(hc)
														?
															'Thanks!'
														:	
															(
															<button
																className	=	"like-portrait"
																onClick		=	{
																				()=>{
																					shared("is it good", path)
																					is_it_good.push(hc)
																					this.state({ last_action:'liked '+hc })
																					}
																				}
								 								>
								 								👍
															</button>
															)
														)
													}
													{//
														disable_download_btn
													?	''
													:	(
														<a
															download="portraitai.com.jpg"
															href	={base64_for[hc] }
															onClick={
																	()=>{
																		shared("downloaded", path)
																		}
																	}
							 								>
							 								<Save_svg />
														</a>
														)
													}
												</div>
											</div>

									</div>
									)
								}
							)
						}




						{
							(
								not_processing_at_the_moment == 0
							&&	considered_mobile
							)
						?
							(
								<div className="trytool-note">
									<Loader />
								</div>
							)
						:	''
						}
					</div>
				</div>

						{//
						}

						<div className="thumb-preview">
						{
						props.results.length > 1
						?
							props.results.map(
								(path, index)	=>
									{
									let ib	 = (index%6==0) ? 'b' : 'i'
									let	photo_id = path.substring( path.lastIndexOf('/') +1, path.lastIndexOf('-_port.') )
									let hc	 = path.hashCode()
									let	path_with_original	= path.replace('-_port', '+_port')
									let self = props.this

									// console.log("showing. hashCodes_of_loaded", hashCodes_of_loaded)

									return (
										<div
											key				={ hc }
											className		="thumb-div"
											style			=	{
																hashCodes_of_loaded.includes( hc )
																?	{}
																:	{display:'none'}
																}
											>
												<img
													className	="thumb-img"
													src			={ path }
													hashcode	={ hc }
													onClick		=	{
																	e=>	{
																		shared('clicked thumb', path)
																		console.log("clicked thumb-img")
																		var el,all
																		all = document.querySelectorAll(".processed-div")
																		try	{
																			all.forEach(img =>
																				{
																				// console.log('img', img)
																				let h_code =	Number.parseInt( img.getAttribute('hashcode') )
																				// console.log('h_code', h_code)
																				if( h_code == hc )
																					{
																					img.scrollIntoView()
																					try	{
																						var gtag = window.gtag
																						gtag('event',	'clicked thumb-img'
																								// ,	{
																									// event_label		:''
																									// }
																									);
																						}
																					catch(er){}
																					}
																				})
																			}
																		catch(e){}
																		}
																	}
													original	="true"
													/>
										</div>
										)//
									}
								)
						:
							''
						}
						</div>

			</div>
			)
	}
///






function	server_url(s='-')
	{
	if	(s=='-')
		s = subdomain
	return	'https://a'+ s +'.portrait-ai.com/v1/'
	}


function	style_url(hex, style, s='-')
	{
	if	(s=='-')
		s = subdomain
	return 'https://a'+ s +'.portrait-ai.com/v1/cropped/'+ hex +'/portraitai.com-'+ style +'.jpg'
	// https://a1.portrait-ai.com/v1/cropped/d9d7485e41fddfaea37841c1ee04f72ffd409b98/portraitAI.com-2.jpg
	}


function	json_to_arr(j)
	{
	let r = []
	for (const k in j)
		{
		r.push( j[k] )
		}
	return r
	}






class Dropzone extends Component {
	constructor(props) 
		{
		super(props)
		this.state =	{
							hightlight			:false 
						,	source_images		:[]
						,	resulting_images	:[]
						,	last_action			:'none'
						}
		this.fileInputRef = React.createRef()

		this.openFileDialog		= this.openFileDialog.bind(this)
		this.onFilesAdded		= this.onFilesAdded.bind(this)
		this.onDragOver			= this.onDragOver.bind(this)
		this.onDragLeave		= this.onDragLeave.bind(this)
		this.onDrop				= this.onDrop.bind(this)
		this.upload				= this.upload.bind(this)
		this.fileListToArray	= this.fileListToArray.bind(this)

		this.successUpload		= this.successUpload.bind(this)
		this.reportError		= this.reportError.bind(this)
		}



	successUpload(e, file)
		{
		// const formData = new FormData()
		// formData.append('ror', e )
		// formData.append('upfile', file)
		// formData.append('u', uid)

		// if(Math.random()>0.9)
		// 	{
		// 	fetch	(
		// 				'https://portraitai.com/p/success-log.php'
		// 			,	{
		// 					method: 'POST'
		// 				,	body: formData // This is your file object
		// 				}
		// 			)
		// 	}

		// if(uid === undefined )
		// 	{
		// 	uid = makeid(4)
		// 	permanent_storage.setItem('uid',uid)
		// 	}

		// if(uid === undefined )
		// 	Sentry.captureMessage('uid === undefined')
		}



	reportError(e, file, when_error)
		{
		let e_low = e.toLowerCase()
		if	(
				retries <3
			&&
				(
					e_low.includes('network')
				||	e_low.includes('fetch')
				||	e_low.includes('connection')
				||	e_low.includes('conexión')
				||	e_low.includes('conexão')
				||	e_low.includes('request')
				||	e_low.includes('cancelled')
				||	e_low.includes('zrušeno')
				||	e_low.includes('nätverksanslutningen')
				||	e_low.includes('gateway ')
				)
			)
			{
			retries++
			setTimeout(
					()=>{
						this.upload(file, when_error, 0)
						}
				,	1100)
			Sentry.captureMessage('Retrying ' + e, 'Retries done: ' + retries)
			}

		// if(Math.random()>0.96 && retries == 0)
		// 	{
		// 	const formData = new FormData()
		// 	formData.append('ror', e.substring(0,45) )
		// 	formData.append('upfile', file)
		// 	formData.append('u', uid)
		// 	fetch	(
		// 				'https://portraitai.com/p/upload.php'
		// 			,	{
		// 					method: 'POST'
		// 				,	body: formData // This is your file object
		// 				}
		// 			)
		// 	}

		Sentry.captureMessage('Debug this ' + e);
		try	{
			var gtag = window.gtag
			gtag('event',	'error'
					,	{
						event_label		:e
						});
			}
		catch(er){}
		}


	openFileDialog() 
		{
		if (this.props.disabled) 
			return
		this.fileInputRef.current.click()
		}

	onFilesAdded(evt) 
		{
		if (this.props.disabled) 
			return
		const files = evt.target.files
		if (this.props.onFilesAdded) 
			{
			const array = this.fileListToArray(files)
			this.props.onFilesAdded(array)
			}
		}

	componentDidMount()
		{
		drop_zone_component = this
		}

	componentWillUpdate(nextProps, nextState)
		{
		// source_imgs	=	nextState.source_images
		}
	componentDidUpdate(prevProps, prevState, snapshot)
		{
		// source_imgs	=	nextState.source_images
		// var el=0
		// if(this.state.last_action=='+ source')
		// 	{
		// 	el = document.querySelectorAll(".my_photo")
		// 	el = el[el.length -1]
		// 	}
		// // else
		// // if(this.state.last_action=='+ result')
		// // 	{
		// // 	el = document.querySelectorAll(".processed")
		// // 	el = el[el.length -1]
		// // 	}

		// if(el)
		// 	// el.scrollIntoView()
		// 	// el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
		// 	window.requestAnimationFrame(function() 
		// 		{
		// 		el.scrollIntoView()
		// 		// el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
		// 		})

		}



	check_which_styles_ready(hash, styles, re_times=0)
		{
		if	(re_times > 25)
			return;

		let self 		=	this
		let	path		=	server_url() + 'c/styles-ready.php?hex=' + hash
		let	fetch_json	=	{
								method	:	'GET'
							,	mode	:	'cors'
							}


		function styles_succ(x)
			{

			if	(
				x.hasOwnProperty('error')
				)
				{
				console.error('received check_which_styles_ready success-error')
				styles_err(x)
				return;
				}

			console.log('styles_succ x', x)
			x = json_to_arr(x)

			let styles_matched		=	styles.filter(	s =>  x.includes(s)	)
			let styles_not_matched	=	styles.filter(	s => !x.includes(s)	)

			if(styles_matched.length)
				{
				let	ex_images		=	self.state.resulting_images
				let	nu_style_imgs	=	styles_matched.map(stl  =>  style_url(hash, stl)  )
				let actually_nu_style_imgs	=	nu_style_imgs.filter(	s => !ex_images.includes(s)	)

				let	res_img =	[
									... ex_images
								,	... actually_nu_style_imgs
								]

				self.setState	(	
									{
										resulting_images:	res_img
									,	last_action		:	'+ result'
									}
								)
				}


			if(styles_not_matched.length)
				setTimeout	(
								()=>
									{
									self.check_which_styles_ready(hash, styles_not_matched, re_times + 1)
									}
							,	900
							)

			console.log('styles_matched', styles_matched)
			console.log('styles_not_matched', styles_not_matched)
			}


		function styles_err(e)
			{
			//	Server issues, probably
			console.error('styles_err', e)
			let f = () => self.upload_image( files_uploaded[hash], styles )
			pick_subdomain(f)
			}


		fetch	(
					path
				,	fetch_json
				)
			.then	(response 	=>	response.json())
			.then	(success 	=> 	styles_succ(success))
			.catch	(error 		=>	styles_err (error))

		}



	make_styles(hash, styles=[1,2])
		{
		let	path		=	server_url() + 'c/make-styles.php'
		const formData	=	new FormData()
		formData.append('hex',		hash)
		formData.append('styles',	JSON.stringify(styles))

		let self = this
		let	fetch_json	=	{
								method	:	'POST'
							,	body	:	formData
							,	mode	:	'cors'
							}


		function styles_succ(x)
			{
			if	(
					x.hasOwnProperty('error')
				&&	x.error == "Original image not found on this server. Maybe, resend the original?"
				)
				{
				let	file = files_uploaded[hash]
				this.upload_image(file, styles)
				}


			setTimeout	(
							()=>
								{
								self.check_which_styles_ready(hash, styles)
								}
						,	500
						)
			}


		function styles_err(e)
			{
			//	Server issues, probably
			console.error('styles_err', e)
			let f = () => self.upload_image( files_uploaded[hash], styles )
			pick_subdomain(f)
			}


		fetch	(
					path
				,	fetch_json
				)
			.then	(response 	=>	response.json())
			.then	(success 	=> 	styles_succ(success))
			.catch	(error 		=>	styles_err (error))
		}




	download_cropped(hash, file)
		{
		send_to_retries = 0
		console.log(hash)
		files_uploaded[hash] = file
		let	cropped_pic_url	=	server_url() + 'cropped/' + hash + '/original.jpg'

		this.make_styles(hash, [1,2])

		not_processing_at_the_moment = 1

		last_alert =	(
						<></>
						)//

		this.setState	({ 
							last_action		:	'cropped'
						,	resulting_images:	[
													... this.state.resulting_images
												,	
													cropped_pic_url
												]
						})
		}




	remove_cropped_images()
		{

		let	submitted_pics	=	{}
		if	(permanent_storage.exists('submitted'))
			{
			submitted_pics = permanent_storage.getItem('submitted')
			submitted_pics = JSON.parse(submitted_pics)
			}

		console.log( submitted_pics )

		}





	upload_image(file, preschedule_styles=[1,2])
		{
		if(uploading_image_at_the_moment)
			return;

		if(upload_error_retries > 3)
			return;

		uploading_image_at_the_moment = 1;

		const formData	=	new FormData()
		formData.append('image'				,	file)
		//TODO: change later
		// formData.append('may_keep'			,	window.api.may_keep)
		formData.append('may_keep'			,	1) 
		formData.append('preschedule_styles',	JSON.stringify(preschedule_styles))

		if(subdomain == -1)
			{
			console.error('all servers full')

			last_alert =	(
							<div className="Sorry">
								We experience a very high traffic at the moment and our servers are full.
								Could you, please, try again in a few minutes?
								<br/>
							</div>
							)//
			not_processing_at_the_moment = 1
			self.setState	({ 
								last_action		:	'failed'
							})
			}


		let	path		=	server_url() + 'c/submit-user-image.php'
		let	fetch_json	=	{
								method	:	'POST'
							,	body	:	formData
							,	mode	:	'cors'
							}

		let	self		=	this


		function succ(success)
			{
			upload_error_retries = 0
			uploading_image_at_the_moment = 0
			console.log("success")
			console.log(success)

			if	(success.hasOwnProperty('crop_hashes'))
				{
				try	{
					console.log('crop_hashes')
					self.download_cropped( success.crop_hashes[0] , file )

					let	submitted_pics	=	{}
					if	(permanent_storage.exists('submitted'))
						{
						submitted_pics = permanent_storage.getItem('submitted')
						submitted_pics = JSON.parse(submitted_pics)
						}

					if	(
						!submitted_pics.hasOwnProperty(subdomain)
						)
						{
						submitted_pics[subdomain]  =  []
						}
						
					submitted_pics[subdomain].push( success.original_hash_hex )
					submitted_pics[subdomain]  =  [... new Set(  submitted_pics[subdomain]  ) ]

					console.log('submitted',  JSON.stringify(submitted_pics) )
					permanent_storage.setItem('submitted',  JSON.stringify(submitted_pics) )
					}
				catch(e)
					{
					console.error(e)
					}

				return;
				}
			else
			if	(success.hasOwnProperty('send_to'))
				{
				console.log('send_to')
				send_to_retries	++
				if(send_to_retries > 10)
					return;

				subdomain = success['send_to']
				subdomain = Number(subdomain)
				self.upload_image(file)
				return;
				}
			else
			if	(success.hasOwnProperty('error'))
				{
				console.log('succ -> error')
				last_alert =	(
								<div className="Sorry">
									Oops!<br/>
									<br/>
									{ success.error }
									<br/>
									<br/>
								</div>
								)//
				not_processing_at_the_moment = 1
				self.setState	({ 
								last_action	:'failed'
								})

				try	{
					setTimeout(
								()=>
									{
									window.requestAnimationFrame(
										()=>{
											var el
											el = document.querySelectorAll(".Dropzone")
											el = el[ el.length-1 ]
											el.scrollIntoView()
											console.log('scrollIntoView to .Dropzone')
											}
										)
									}
								,	100
								)
					}
				catch(e){}

				return;
				}


			console.log("the unsuccess", success)
			not_processing_at_the_moment	= 1
			self.setState	({ 
								last_action		:	'failed'
							})
			}




		function err(error)
			{
			//	Server issues, probably
			upload_error_retries ++
			uploading_image_at_the_moment	= 0
			not_processing_at_the_moment	= 1
			console.log("error")
			console.log(error)
			self.setState	({ 
								last_action		:	'failed'
							})

			let f = () => self.upload_image( file, preschedule_styles )
			pick_subdomain(f)
			}



		try	{
			var reader = new FileReader()
			reader.onload = function(evt) 
				{
				let	val = enc( evt.target.result )
				formData.append('val',	val)

				fetch	(
							path
						,	fetch_json
						)
					.then	(response 	=>	response.json())
					.then	(success 	=> 	succ(success))
					.catch	(error 		=>	err(error))
				}

			reader.readAsBinaryString(file)
			}
		catch(e)
			{
			formData.append('val', -1)

			fetch	(
						path
					,	fetch_json
					)
				.then	(response 	=>	response.json())
				.then	(success 	=> 	succ(success))
				.catch	(error 		=>	err(error))
			}
		}





	upload(file, times, should_nocrop=0, pic_id=0, one_more_pic=0)
		{
		this.upload_image(file)
		/*
		return 0;
		should_nocrop_previous = should_nocrop

		if(one_more_pic)
			{
			not_processing_at_the_moment = 3
			one_more_pic_pushed_times++
			}


		this.setState(	{
						last_action	:'sending upload'
						})


		let	waterm = 1
		try	{
			if('hack_w' in window)
				waterm = 0
			}
		catch(e){}

		const formData = new FormData()
		formData.append('id', '11111-' + uid )

		formData.append('type', 1)

		if(waterm && do_we_add_watermark)
			formData.append('wm', 3)

		if(!pic_id)
			formData.append('image', file)
		else
			formData.append('code' , pic_id)

		formData.append('image', file)


		//	This will of course, not set val properly. This is just a reminder.
		try	{
			var reader = new FileReader();
			reader.onload = function(evt) 
				{
				console.log( "val", enc( evt.target.result ) );
				};

			reader.readAsBinaryString(file);
			

			formData.append('val', 1)
			}
		catch(e)
			{
			formData.append('val', -1)				
			}


		// formData.append('filter_ids', JSON.stringify([1,2,3,4,5]))
		formData.append('styles_to_schedule', JSON.stringify([1,2,3,4,5]))
		formData.append('may_keep', 1)

		
		let collage = 0
		// if(times <= 0)
		// 	{
		// 	collage = 1
		// 	formData.append('collage', 1)
		// 	}


		let response_code = -1;

		function resp(response)
			{
			response_code = response.status
			console.log("Response code " + response_code)
			return response.text()
			}

		function succ(success, times)
			{
			console.log("success")
			console.log(success) // Handle the success response object
			// document
			// 	.getElementById('processed')
			// 	.setAttribute('src', 'https://portraitplus.facefun.ai:8443/Port/' + success)
			if('No faces found'	== success)
				{
				// this.reportError(response_code + success, file)
				last_alert =	(
								<div className="Sorry">
									Sorry!<br/>
									<br/>
									Portraits were generated in disoriented mode, meaning we've processed your photo without properly framing your face.
									So the quality was likely very poor.
									<br/>
									<br/>
									We are working intensely on improving the system, because it's messed up at the moment.
									It actually fails to correctly locate faces on the picture in about 30% of cases. 🤦‍ 
									<br/>
									<br/>
									Try another photo.
									<br/>
									<br/>
								</div>
								)
				//
				if(!should_nocrop)
					// this.upload(file, times, 1)
					this.upload(file, times, 1)

				// not_processing_at_the_moment = 1

				// this.setState(	{ 
				// 					last_action	:'failed'
				// 				})
				// try	{
				// 	setTimeout(()=>
				// 			{
				// 			window.requestAnimationFrame(
				// 				()=>{
				// 					var el
				// 					el = document.querySelectorAll(".Dropzone")
				// 					el = el[ el.length-1 ]
				// 					el.scrollIntoView()
				// 					}
				// 				)
				// 			}
				// 			,	100)
				// 	}
				// catch(e){}
				}
			else
			if('Invalid image format'	== success)
				{
				this.reportError(response_code + success, file, times)
				last_alert =	(
								<div className="Sorry">
									Sorry!<br/>
									Invalid format. =(
										<br/>
									Please, a photo in .JPG or .PNG format.
									<br/>
								</div>
								)
				//
				not_processing_at_the_moment = 1
				this.setState(	{ 
									last_action	:'failed'
								})
				try	{
					setTimeout(()=>
							{
							window.requestAnimationFrame(
								()=>{
									var el
									el = document.querySelectorAll(".Dropzone")
									el = el[ el.length-1 ]
									console.log('scrollIntoView to .Dropzone')
									el.scrollIntoView()
									}
								)
							}
							,	100)
					}
				catch(er){}
				}
			else
			if(success.substring(0,5) == 'port/')
				{
				if(times==0)
					this.successUpload(success.substring(5), file)

				report_img_load()

				if(!should_nocrop)
					last_alert = ''


				// if(times)
				if(times == num_default_imgs_to_generate)
					not_processing_at_the_moment = 0
				// else
				if(times == 0)
					not_processing_at_the_moment = 2	//	last


				console.log('times', times, 'not_processing_at_the_moment', not_processing_at_the_moment)


				let	cur_pic = ""
				cur_pic = success.substring(5)
				cur_pic = cur_pic.substring(0, cur_pic.length - 10)// - '-_port.jpg'.length)

				if(collage)
					{
					collages.push(cur_pic)
					}

				// console.log('xxXXxx this.state.resulting_images', this.state.resulting_images)

				let where_to_send = 'https://ai.portraitai.com/Port/' + success
				if(Math.random()>0.5)
					where_to_send = 'https://portraitplus.facefun.ai/Port/' + success

				this.setState(	{ 
									resulting_images:
											[
											... this.state.resulting_images
											// ,	'https://portraitplus.facefun.ai/Port/' + success
											,	where_to_send
											]
								,	last_action	:'+ result'
								})
				// console.log('yyYYyy this.state.resulting_images', this.state.resulting_images)



				if(should_nocrop)
					{
					not_cropped.push(cur_pic)
					}

				if(!pic_id)
					{
					pic_id = cur_pic
					pic_id_previous = pic_id
					console.log('pic_id set to',pic_id)
					}

				if(times == num_default_imgs_to_generate)
					{
					while(times>0)
						{
						times--;
						this.upload(file, times-1, should_nocrop, pic_id)
						}
					}

				}
			else
				{
				this.reportError(response_code + 'Server said something went wrong: '+success, file, times)
				last_alert =	(
								<div className="Sorry">
									Sorry!<br/>
									<br/>
									We are working intensely on improving the system, but it's messed up at the moment.
									<br/>
									<br/>

									Server said something went wrong. =(<br/>
									{'"'+success+'"'}
									<br/>
									<br/>
									Please, can you try a different photo?
									<br/>
								</div>
								)
				//
				not_processing_at_the_moment = 1
				this.setState(	{ 
									last_action	:'failed'
								})
				try	{
					setTimeout(()=>
							{
							window.requestAnimationFrame(
								()=>{
									var el
									el = document.querySelectorAll(".Dropzone")
									el = el[ el.length-1 ]
									el.scrollIntoView()
									console.log('scrollIntoView to .Dropzone')
									}
								)
							}
							,	100)
					}
				catch(e){}
				}
			}


		function	err(error, times)
			{
			console.log("error")
			console.log(error)
			console.log('error.message', error.message)
			error = error.message
			this.reportError(response_code + "catch-error: " + error, file, times)
			not_processing_at_the_moment = 1

			let lowc = error.toLowerCase()
			try	{
				if	(
						lowc.includes("gateway")
					||	lowc.includes("fetch")
					||	lowc.includes("request")
					||	lowc.includes("connection")
					||	lowc.includes("conexión")
					||	lowc.includes("network")
					)
					error = "AI seems to be under extreme pressure right now. Most likely it's going to start working fine in a couple of minutes. AI would be glad to paint your portrait then. 😉 Sorry for wasting your time like that. It's precious. We're experiencing peak load due to virality of Portrait AI and the Neural Net of our AI is huge, slow and very costly to run. But we're working to improve it, so bear with us. Thanks!"
				}
			catch(e)
				{}

			last_alert =	(
							<div className="Sorry">
							Sorry!<br/>
							<br/>
							We are working intensely on improving the system, but it's messed up at the moment.
							<br/>
							<br/>
									Server says:<br/>
									{'"'+error+'"'}

							<br/>
 							</div>
							)
			//
			this.setState(	{ 
								last_action	:'failed'
							})

			if(times == num_default_imgs_to_generate)
				{
				try	{	
					let dropz = document.querySelector(".Dropzone")
					dropz.scrollIntoView()
					}
				catch(e)
					{}
				}
			}


		err	= err.bind(this)
		succ= succ.bind(this)
		resp= resp.bind(this)


		fetch(
				// 'https://i9-0.portrait-ai.com/image-processor.php'
				// 'https://i9-0.portrait-ai.com/v1/c/submit-user-image.php'
				'https://a1.portrait-ai.com/v1/c/submit-user-image.php'
				// 'https://ai.portraitai.com/Port/MakePort'
			,	{
					method: 'POST'
				,	body: formData // This is your file object
				,	mode: 'cors'
				}
			)
		// fetch(
		// 		'https://portraitplus.facefun.ai/Port/MakePort'
		// 		// 'https://ai.portraitai.com/Port/MakePort'
		// 	,	{
		// 			method: 'POST'
		// 		,	body: formData // This is your file object
		// 		,	mode: 'cors'
		// 		}
		// 	)
		.then(
			(response) => 
				{
				return resp(response)
				}
			)
		.then(
			success => 
				{
				succ(success, times)
				}
			)
		.catch(
			error => 
				{
				err(error, times)
				}
			);
		*/
		}



	onDragOver(evt) 
		{
		evt.preventDefault()
		if (this.props.disabled) 
			return
		this.setState(	{
							hightlight: true 
						,	last_action	:'+ highlight'
						})
		}


	onDragLeave() 
		{
		this.setState(	{
							hightlight: false 
						,	last_action	:'- highlight'
						})
		}


	onDrop(event)
		{
		event.preventDefault()

		if (this.props.disabled) 
			return

		const files = event.dataTransfer.files
		if (this.props.onFilesAdded) 
			{
			const array = this.fileListToArray(files)
			this.props.onFilesAdded(array)
			}

		this.setState(	{
							hightlight: false 
						,	last_action	:'- highlight'
						})
		}



	fileListToArray(list) 
	  	{
  		console.log(this.state.source_images)
		const array = []
		for (var i = 0; i < list.length; i++) 
			{
			array.push(list.item(i))

			var reader = new FileReader();
			var self = this;
			let	list_i = list.item(i)
			reader.onload = function (e) 
					{
					// document
					// 	.getElementById('my_photo')
					// 	.setAttribute('src', e.target.result)
					not_processing_at_the_moment = 3
					// check_on_upload_and_stop_if_taking_too_long
					self.setState(	{ 
										source_images:
											[
											... self.state.source_images
											,	e.target.result
											]
									,	last_action	:'+ source'
									})


					try	{
						if('hack_pic_count' in window)
							{
							num_default_imgs_to_generate = window['hack_pic_count']
							console.log('num_default_imgs_to_generate',num_default_imgs_to_generate)
							}
						}
					catch(e){}

					self.upload(  list_i , num_default_imgs_to_generate , 0 )
					};
			reader.readAsDataURL( list_i );
			}
		return array
		}



	render()
		{
		console.log(this.state)
		console.log("not_processing_at_the_moment", not_processing_at_the_moment)
		var select_pic = 	(
							<div>Pick Selfie
							<br/>
							<i id="dnd">(Drag & Drop or Click here)</i>
							</div>
							);


		if(considered_mobile)
			select_pic =	(
							<div className="Pick_Selfie_mobile">
								Pick Selfie
							</div>
							);


		//
		// let dropzone=(
		// 				<div className="issue">
						
		// 					We need to optimize, scale and make lots of fixes before Portrait AI will be back. 
		// 					We used to spend more on our servers than we earned.
		// 					<br/>
		// 					<br/>
		// 					PortraitAI is currently only available inside <a
		// 						href={itunes_link}
		// 						onClick=	{
		// 									(e)=>
		// 										{
		// 										gtag('event',	'itunes_link'
		// 												,	{
		// 													event_label		:'Check out Portrait AI app',
		// 													event_category	:'link click'
		// 													})
		// 										}
		// 									}
		// 						>Portrait AI iOS app.</a>
		// 					<br/>
		// 					<br/>
		// 					There is no Android app. But we are making one.
		// 					<br/>
		// 					<br/>
		// 					Sorry! Come back early July after we fix and improve everything!
		// 				</div>
		// 			)//

		let dropzone=(
						<div>
						{
							not_processing_at_the_moment == 1
						?	
							(
							<div>


								{
								// <div className="over-how-note">
								// 	Sometimes, AI fails to properly locate faces.
								// 	Sorry!
								// 	Fixing that at the moment.
								// 	If one photo fails, try another 🧘
								// </div>
								}

								<div
									className=	{	//
													'Dropzone '
												+	(this.state.hightlight ? 'Highlight ' : '')
												+	(considered_mobile ? 'mobile_dropzone ' : '')
												}
									onDragOver={this.onDragOver}
									onDragLeave={this.onDragLeave}
									onDrop={this.onDrop}
									onClick={this.openFileDialog}
									style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
									>
									<input
									ref={this.fileInputRef}
									className="FileInput"
									type="file"
									onChange={this.onFilesAdded}
									/>
									
									<span>{last_alert}</span>
									<div
										className="Icon"
										>
										{//
										considered_mobile 
										?	(<PortraitWhite />)	
										:	(<Portrait />)		
										}
									</div>
									{select_pic}
								</div>
							</div>
							)
						:	''//

						}
						</div>
					)//




		return (
				<div id="img_upload">
				
					{
					// <a onClick=	{()=>	
					// 				{
					// 				this.remove_cropped_images()
					// 				}
					// 			}>
					// 	Privacy
					// 	</a>
					}


					{/**/
					// dropzone
					// <div className="howto-above-upload">
					// 	<HowToImg />
					// </div>


					// <div className="issue">
						// PortraitAI is currently offline, sorry! We might be back in a few days or so.
					// </div>
					}

					{
					// 	!selected_visitor
					// ?	<Email_Signup />
					// :	''
					}


					<Uploaded_Photos
						sources	={this.state.source_images	}
						results	={this.state.resulting_images}
						this	={this}
						/>

					{	//
						this.state.resulting_images.length >= 1
					?	dropzone
					:	''
					}

					<Photos
						sources	={this.state.source_images	}
						results	={this.state.resulting_images}
						this	={this}
						/>

					{//
						// 		this.state.resulting_images.length >= 1
						// 	&&	not_processing_at_the_moment == 1
						// 	&&	one_more_pic_pushed_times < 5
						// ?	(
						// 	<div>
						// 		<a
						// 			onClick={
						// 					()=>{
						// 						try	{
						// 							gtag('event',	one_more_pic_pushed_times
						// 									,	{
						// 											event_label		:one_more_pic_pushed_times
						// 										,	event_category	:'One more for this photo'
						// 										});
						// 							console.log("uploaded")
						// 							}
						// 						catch(er){}

						// 						this.upload('',0, should_nocrop_previous, pic_id_previous, 1)
						// 						}
						// 					}
						// 			className="one-more"
						// 			>
						// 			One more for this photo
						// 		</a>
						// 	</div>
						// 	)
						// :	''
					}



					{
					// Painting. Our servers are so cramped right now you should probably go make yourself some tea... I did warn you...
					not_processing_at_the_moment == 3
					?	(<div className="hopeful">
							Painting... Should only take a few seconds.
							<br />
							{
							// 	is_targeted_app_platform
							// ?	(
							// 	is_iOS
							// 	?	(
							// 			<span>
							// 				Generate more and faster <a
							// 						href={ itunes_link } 
							// 						target="_blank"
							// 						onClick=	{
							// 									(e)=>
							// 										{
							// 										gtag('event',	'itunes_link'
							// 												,	{
							// 													event_label		:'Generate more and faster in our free app',
							// 													event_category	:'link click'
							// 													})
							// 										}
							// 									}
							// 						>
							// 					in our free app
							// 				</a> that has lots of cool live AI filters
							// 			</span>
							// 		)
							// 	:	'While AI is drawing, check out this cool app:'
							// 	)
							// :	(
							// 	<span>
							// 		<br/>
							// 		In several days our AI is going to be able to draw for you even better portraits faster. At the moment, due to peak viral load we often experience problems. Sorry if that happens.
							// 	</span>
							// 	)
							}
							<br />
							<br />
							{
							// 	this.state.resulting_images.length >= 1
							// ?	(
							// 	<div>
							// 		<a
							// 			onClick={
							// 					()=>{
							// 						this.upload('',0, should_nocrop_previous, pic_id_previous, 1)
							// 						}
							// 					}
							// 			className="one-more"
							// 			>
							// 			Paint one more
							// 		</a>
							// 	</div>
							// 	)
							// :	''

							// is_targeted_app_platform
							// ?	''
							// 	// (
							// 	// <OurApp />
							// 	// )
							// :	(
							// 	<div>
							// 		<Norah />
							// 	</div>
							// 	) //
							}
							
						</div>
						)
					:	'' //
					}

					{
					// <div id="trytool-note-inside">
					// {
					// // 	not_processing_at_the_moment == 0
					// // ?
					// // 	// (
					// // 	// <div className="trytool-note">
					// // 	// 	{
					// // 	// 	is_targeted_app_platform
					// // 	// 	?	''
					// // 	// 		// (
					// // 	// 		// <Norah />
					// // 	// 		// )
					// // 	// 	:	(
					// // 	// 		<Norah />
					// // 	// 		) //
					// // 	// 	}
					// // 	// </div>
					// // 	// ) //
					// // 	''
					// // :	''
					// }
					// </div>
					}



					{//
					// is_targeted_app_platform
					// ?	(
					// 	is_iOS
					// 	?	<OurApp />
					// 	:	
					// 		<Android_App_Presentation />
					// 		// (
					// 		// <div className="android-ad">
					// 		// 	<Ad slot={5416008550} />
					// 		// </div>
					// 		// )
					// 		//<Retouch />
					// 	)
					// :	
					// 	''
					// 	// (
					// 	// 	{
					// 	// 	<Loader />
					// 	// 	}
					// 	// )
					// //
					}





					{	//
					// 	this.state.resulting_images.length >= 1
					// ?	dropzone
					// :	''
					}



					{//*/
							this.state.source_images.length == 0
						&&	this.state.source_images.length <  4
					?
						''
					:	
						(
						<div>
							<div className="above-trytool-note">
								Portrait quality differs a lot from photo to photo. Retry with different photos. ⭣
								
								<div className="Suggestions">
								Suggestions:  
								</div>
								<ul>
									<li>
										Make sure that all of your head and most of your hair is on the photo.
									</li>
									{//
									}
									<li>
										🚫👓‍🕶‍ If you're wearing glasses, better take them off.
										Especially if you're wearing shades (dark glasses).
									</li>
									<li>
										Best if your face takes around 40% of photo width and height. Centered.
									</li>
									<li>
										Neutral emotion might work best.
									</li>
								</ul>
							</div>
						</div>
						)
					}


					{	//
						this.state.resulting_images.length >= 1
					?	dropzone
					:	''
					}
					
					{//
					// is_targeted_app_platform
					// ?	(
					// 	is_iOS
					// 	?	<OurApp />
					// 	:	
					// 		<Android_App_Presentation />
					// 		// (
					// 		// <div className="android-ad">
					// 		// 	<Ad slot={5416008550} />
					// 		// </div>
					// 		// )
					// 		//<Retouch />
					// 	)
					// :	
					// 	''
					// 	// (
					// 	// 	{
					// 	// 	<Loader />
					// 	// 	}
					// 	// )
					//
					// <p className="issue">
					// For now, Portrait AI is only available via our apps.
					// <br/>
					// <br/>
					// <Download />
					// <br/>
					// Portrait AI will once again be available through this website early next month. 
					// </p>

					
					// <div className="android-ad">
					// 	<Ad slot={5416008550} />
					// </div>
					}



					<div>
					{	//
						not_processing_at_the_moment == 1
					?
						(
						<div className="trytool-note">
							At the moment, Portrait AI is only available in iOS and Android apps developed by some people I know.
							<br/>
							<br/>
							Currently, the AI portrait generator has been trained mostly on portraits of people of European ethnicity. We're planning to expand our dataset and fix this in the future. 
							At the time of conceptualizing this AI, authors were not certain it would turn out to work at all. 
							This is close to state of the art in AI at the moment.
							<br/>
							<br/>
							Sorry for the bias in the meanwhile. Have fun!
							<br/>
							<br/>
							{
							// I made this website to support my friends who are developing <a
							// 	href={itunes_link}
							// 	onClick=	{
							// 				(e)=>
							// 					{
							// 					gtag('event',	'itunes_link'
							// 							,	{
							// 								event_label		:'developing portraitai app',
							// 								event_category	:'link click'
							// 								})
							// 					}
							// 				}

							// 	>Portrait AI app</a>.
							// One of the AI's they've made is this portrait generator. It is processed on a server.
							// The rest of their neural nets do live video on iPhone.
							// I liked this portrait AI a lot so figured I should make a website for it.
							// <br/>
							// <br/>
							}
							Also, check out my online <a href="https://fsymbols.com/generators/">font generators</a> for cool copy and paste fonts like 𝒕𝒉𝒊𝒔, 𝓽𝓱𝓲𝓼 and 🆃🅷🅸🆂. Or my <a href="https://tell.wtf/">emoji and symbol recognition AI 
							 on Tell.wtf</a>
							<br/>
							<br/>

							<h1>Avatar maker</h1>

							Portrait AI
							is a great <strong>avatar generator</strong>. It adds that bit of anonymity and refinement online.
							I used it personally to generate my own avatar on Facebook and other places.
							Traditionally when you'd be looking for how to make an avatar of yourself
							for a game you'd look to make a sort of personalized
							cartoon character. Like you can do with Apple Memoji.
							You wouldn't think "Oh, let me generate a classic portrait avatar"
							or "let me ask someone to paint a portrait of me for an avatar".
							But now this is an option. 
							And right now this look is even more unique.

							<br/>
							<br/>
							{
							// Enjoy! And install the <a
							//  	href={ itunes_link }
							// 	onClick=	{
							// 				(e)=>
							// 					{
							// 					gtag('event',	'itunes_link'
							// 							,	{
							// 								event_label		:'And install the FaceFun app',
							// 								event_category	:'link click'
							// 								})
							// 					}
							// 				}
							//  >Portrait AI app on an iPhone</a> or <a
							//  	href={ g_play_link }
							// 	onClick=	{//
							// 				(e)=>
							// 					{
							// 					gtag('event',	'g_play_link'
							// 							,	{
							// 								event_label		:'And install the FaceFun app',
							// 								event_category	:'link click'
							// 								})
							// 					}
							// 				}
							//  >Android Portrait AI app</a> from Google Play.
							}


							<h2>Copyleft</h2>
							<p>
								Art generated by portraitai.com is completely free to use for any purpose.
							</p>


						</div>
						)
					:	''
					}
					</div>



				</div>
				)
		}
}

export default Dropzone
