import	React
	,	{	
			useState
		,	useEffect
		,	Component
		}	from 'react'




function validateEmail(email) 
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }



function	shared(platform, photo_id)	//	photo_id = full path url to image
	{
	if	(
			photo_id.substring(photo_id.length-12)
		==	"original.jpg"
		)
		{
		return;
		}

	try	{
		const	formData	=	new FormData()
		let		from_		=	photo_id.indexOf('/cropped/')

		formData.append	(
							'hex'
						,   photo_id.substring	(
													from_ + 9
												,	from_ + 9 + 40
												)
						)

		let	stl = Number(photo_id.substring( photo_id.length - 15 ).replace(/\D/g,''))
		console.log(photo_id)
		console.log(stl)
		formData.append('style',  stl)

		fetch	(
					photo_id.substring(0,from_) + '/c/favourite.php'
				,	{
						method	: 'POST'
					,	body	: formData // This is your file object
					}
				)

		console.log("shared", photo_id, platform)
		

		// var gtag = window.gtag
		// gtag('event',	platform
		// 		,	{
		// 			event_label		:photo_id,
		// 			event_category	:'share_button_clicked'
		// 			});
		}
	catch(er){}
	}







function	Email_Signup(props)
	{
	const [emailSent, setEmailSent] = React.useState(0)
	return	(
				<div>
				</div>
			)


	if(emailSent == 0  ||  emailSent == -1)
		return	(
				<div className="email">
					<div className="email-message">
						Be the first 
						<br/>
						to play
						<br/>
						with the new
						<br/>
						Portrait AI!
					</div>

					<div>
						<input className="email-email" id="email-email-id" type="text" placeholder="you@email.com" />
						<input className="email-button" type="button" value="Get Notified"
							onClick={
									(e)=>
										{
										let	email = document.getElementById('email-email-id').value
										if	(validateEmail( email ))
											{
											// console.log(email)
											const	formData	=	new FormData()
											formData.append	(
																'email'
															,   email
															)
											fetch	(
														'https://a6.portrait-ai.com/v1/c/email.php'
													,	{
															method	: 'POST'
														,	body	: formData // This is your file object
														}
													)

											setEmailSent(1)
											}
										else
											{
											// console.error(email)
											setEmailSent(-1)
											}
										}
									}
						 />
					</div>
					{
						emailSent == -1
					?	(
						<div className="email-invalid">
							Doesn't seem like a valid email...
						</div>
						)
					:	''
					}

				</div>
				)
	else
		return	(
				<div className="email">
						Congrats!<br/>
						It's going to be fun!
				</div>
				)
	}


export	{
			Email_Signup
		}