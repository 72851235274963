import React from 'react';
import	{
			itunes_link
		,	g_play_link
		}	from	'./links.js'
import	{
			os
		,	os_detail
		,	browser_detail
		,	considered_mobile
		,	is_iOS
		}					from	'./platform.js'
var gtag = window.gtag

function DownloadOnAppstore() 
	{
	// if(is_iOS)
		return	(
				<div className="download-on-appstore">
					<a
						href={itunes_link}
						onClick=	{
									(e)=>
										{
										gtag('event',	'itunes_link'
												,	{
													event_label		:'appstore svg (in download-on-appstore)',
													event_category	:'link click'
													})
										}
									}

						>
						<img id="ios-btn"	src="./images/appstore.svg" width="280" />
					</a>
				</div>
				)
	// else
	// 	return	(
	// 			<div className="app-ad_was_here">
	// 			</div>
	// 			)
	}

function Download_On_Google_Play() 
	{
	// if(is_Android)
		return	(
				<div className="download-on-appstore">
					<a
						href={g_play_link}
						onClick=	{
									(e)=>
										{
										gtag('event',	'g_play_link'
												,	{
													event_label		:'appstore svg (in Download_On_Google_Play)',
													event_category	:'link click'
													})
										}
									}

						>
						<img id="ios-btn"	src="./images/google-play.svg" width="280" />
					</a>
				</div>
				)
	// else
	// 	return	(
	// 			<div className="app-ad_was_here">
	// 			</div>
	// 			)
	}

function Download() 
	{
	if(os == "Android")
		return	(
				<div className="app-ad">
					<Download_On_Google_Play />
				</div>
				)

	if(os == "iOS")
		return	(
				<div className="app-ad">
					<DownloadOnAppstore />
				</div>
				)
	else
		return	(
				<div className="app-ad">
					<Download_On_Google_Play />
					<br/>
					<DownloadOnAppstore />
				</div>
				)
	}



export default Download;
export 	{
			DownloadOnAppstore
		,	Download_On_Google_Play
		,	Download
		};
