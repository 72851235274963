import	React
	,	{	
			useState
		,	useEffect
		}	from 'react'
import	{
			load_JS
		}	from './load_js_css'

let	ads_initialized	=	0

function	init_ads()
	{
	// if	(!ads_initialized)
	// 	{
	// 	ads_initialized	=	1
	// 	try	{
	// 		load_JS('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js')
	// 		}
	// 	catch(e){}
	// 	}
	}

init_ads()


//	MUST PASS props.slot!
function	Adsense(props)
	{
	// useEffect(() => 
	// 	{
	// 	(window.adsbygoogle = window.adsbygoogle || []).push({})
	// 	})
	
	// console.log('upd')
	return	(
			// <>
			// <>
				<ins
				className="adsbygoogle"
				style={{"display":"block"}}
				data-ad-client="ca-pub-7172517222009910"
				data-ad-slot=	{
								props.slot
								}	
				data-ad-format="auto"
				data-full-width-responsive="true" />
			// </>
			// </>
			)
	// return (<></>)
	}

const	Ad	=	React.memo(Adsense)


// (adsbygoogle = window.adsbygoogle || []).push({});
// export	default		React.memo(Ad)	as	adse
export	{
			// React.memo(Ad)	as	default
			Ad
		,	init_ads
		}