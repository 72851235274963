import	React
	,	{	
			useState
		,	useEffect
		}	from 'react'
import	{
			load_JS
		}	from './load_js_css'
import	{
			itunes_link
		,	retouch_link
		,	g_play_link
		}					from	'./links.js'
import	{
		DownloadOnAppstore
		}	from './Download';
import	{
		default_subdomain_list
		}	from './domains.js';
var gtag = window.gtag


function sample(array) 
	{
	return array[Math.floor(Math.random() * array.length)];
	}

// let	video_server	=	'https://a' + sample( default_subdomain_list ) + '.portrait-ai.com/media/'
let	video_server	=	'https://portraitai.com/media/'

function	Norah_(props)
	{
				// <video autoplay="true" loop="true" muted="true" playsinline="true" class="video-container norah-video video-container-overlay">
	return	(
			<div className="fullscreen-video-wrap" >
			
				<a
					href={itunes_link}
					target="_blank"
					onClick=	{
								(e)=>
									{
									gtag('event',	'itunes_link'
											,	{
												event_label		:'Norah Guinea video',
												event_category	:'link click'
												})
									}
								}
					>
					<video autoPlay={1} loop={1} muted={1} playsInline={1} className="video-container norah-video video-container-overlay">
						<source src={video_server + "norah.webm"	} type="video/webm" />
						<source src={video_server + "norah-264.mp4"	} type="video/mp4" />
						<source src={video_server + "norah.mov"		} type="video/quicktime" />
						<source src={video_server + "norah.mp4"		} type="video/mp4; codecs=hevc" />
					</video>            
				</a>

			</div>
			)

				// <video playsinline loop="true" autoPlay="true" muted="true" autobuffer="true" poster="./images/KSGGdLuVkuE9+_port.jpg">
	// return	(
	// 		<div className="fullscreen-video-wrap" dangerouslySetInnerHTML={{ __html: `
	// 			<video autoplay loop muted playsinline class="video-container norah-video video-container-overlay">

	// 			<source src="./media/norah.webm" type="video/webm" />
	// 			<source src="./media/norah-264.mp4"  type="video/mp4" />
	// 			<source src="./media/norah.mov"	 type="video/quicktime" />
	// 			<source src="./media/norah.mp4"  type="video/mp4; codecs=hevc" />


	// 			</video>            
	// 		` }}></div>
	// 		)
	// return	(
	// 		<a href={itunes_link} target="_blank">
	// 			<video className="video-container norah-video video-container-overlay" autobuffer="true" autoPlay="true" loop="true" muted="true">
	// 				<source type='video/mp4; codecs="hvc1"' src="./media/norah.mp4" />
	// 				<source type="video/webm" src="./media/norah.webm" />
	// 			</video>
	// 		</a>
	// 		)
	}
const	Norah	=	React.memo(Norah_)


function	Snails_(props)
	{
	// return	(
	// 		<div className="fullscreen-video-wrap" dangerouslySetInnerHTML={{ __html: `
	// 			<video autoplay loop muted playsinline class="video-container norah-video video-container-overlay">

	// 			<source src="./media/snails.webm"		type="video/webm" />
	// 			<source src="./media/snails-264.mp4" 	type="video/mp4" />
	// 			<source src="./media/snails.mp4"		type="video/mp4; codecs=hevc" />


	// 			</video>            
	// 		` }}></div>
	// 		)

	// return	(
	// 		<a href={itunes_link} target="_blank">
	// 			<video className="video-container norah-video video-container-overlay" autobuffer="true" autoPlay="true" loop="true" muted="true">
	// 				<source type='video/mp4; codecs="hvc1"' src="./media/snails.mp4" />
	// 				<source type="video/webm" src="./media/snails.webm" />
	// 			</video>
	// 		</a>
	// 		)
				// <video autoplay="true" loop="true" muted="true" playsinline="true" class="video-container norah-video video-container-overlay">
	return	(
			<div className="fullscreen-video-wrap" >
				<a
					href={itunes_link}
					target="_blank"
					onClick=	{
								(e)=>
									{
									gtag('event',	'itunes_link'
											,	{
												event_label		:'snails video',
												event_category	:'link click'
												})
									}
								}
					>
					<video autoPlay={1} loop={1} muted={1} playsInline={1} className="video-container norah-video video-container-overlay">
						<source src={video_server + "snails.webm"	}	type="video/webm" />
						<source src={video_server + "snails-264.mp4"} 	type="video/mp4" />
						<source src={video_server + "snails.mp4"	}	type="video/mp4; codecs=hevc" />
					</video>            
				</a>

			</div>
			)
	}
const	Snails	=	React.memo(Snails_)




function	Transitions_(props)
	{
	return	(
			<video autoPlay={1} loop={1} muted={1} playsInline={1} className="video-container norah-video video-container-overlay">
				<source src={video_server + "transitions.mp4"} 	type="video/mp4" />
			</video>            
			)
	}
const	Transitions	=	React.memo(Transitions_)


function	Transitions_1_(props)
	{
	return	(
			<div className="fullscreen-video-wrap" >
				<a
					href={itunes_link}
					target="_blank"
					onClick=	{
								(e)=>
									{
									gtag('event',	'itunes_link'
											,	{
												event_label		:'girl video',
												event_category	:'link click'
												})
									}
								}
					>
					<Transitions />
				</a>

			</div>
			)
	}
const	Transitions_1	=	React.memo(Transitions_1_)



function	Transitions_Android_(props)
	{
	return	(
			<div className="fullscreen-video-wrap" >
				<a
					href={g_play_link}
					target="_blank"
					onClick=	{
								(e)=>
									{
									gtag('event',	'g_play_link'
											,	{
												event_label		:'girl video',
												event_category	:'link click'
												})
									}
								}
					>
					<Transitions />
				</a>

			</div>
			)
	}
const	Transitions_Android	=	React.memo(Transitions_Android_)



function	Retouch_(props)
	{
	return	(
			<div className="fullscreen-video-wrap" >
				<div className="retouch_about">
				<a
					href={retouch_link}
					target="_blank"
					onClick=	{
								(e)=>
									{
									gtag('event',	'retouch_link'
											,	{
												event_label		:'Android: TouchRetouch is a great app.',
												event_category	:'link click'
												})
									}
								}
					>TouchRetouch</a> is a great app. #1 in charts on both Google Play and App Store in
				 photo editing.
				<br/>
				 	<div className="retouch-check-it">
				 		<i>Check it out!</i> Buying it, you will also support PortraitAI.com
				 	</div>
				</div>
				
				<br/>
				
				<a
					href={retouch_link}
					target="_blank"
					onClick=	{
								(e)=>
									{
									gtag('event',	'retouch_link'
											,	{
												event_label		:'Android: Retouch Video',
												event_category	:'link click'
												})
									}
								}
					>
					<video autoPlay={1} loop={1} muted={1} playsInline={1} className="video-container norah-video video-container-overlay">
						<source src="./media/retouch.mp4"		type="video/mp4" />
					</video>            
				</a>
				
				<br/>
				
				<a
					href={retouch_link}
					target="_blank"
					onClick=	{
								(e)=>
									{
									gtag('event',	'retouch_link'
											,	{
												event_label		:'Android: Google Play btn',
												event_category	:'link click'
												})
									}
								}
					>
					<img className="google_play" src="./images/google-play.svg" />
				</a>
			</div>
			)
	}
const	Retouch	=	React.memo(Retouch_)




function	OurApp_(props)
	{
	return	(
			<div className="our-app">
				<Snails />

					<h2 className="like-portrait-ai">Like Portrait AI?</h2>

					<a href={itunes_link} target="_blank" className="check-out-our-app">
						We also made live AI filters!
					</a>

				<br />
				<DownloadOnAppstore />
				<br />
				<Norah  />
					
			</div>
			)
	}
const	OurApp	=	React.memo(OurApp_)

export	{
			Norah
		,	Snails
		,	Transitions
		,	Transitions_1
		,	Transitions_Android
		,	Retouch
		,	OurApp
		}